import React from 'react'
import algoliasearch from 'algoliasearch'
import { connect } from 'react-redux'
import { InstantSearch, Configure, Index } from 'react-instantsearch-dom'

import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'

import {
  FETCH_USER_WITH_PERMISSIONS,
  FETCH_USER_WITH_PERMISSIONS_PUBLIC,
} from 'mednet-cns/src/reducers/user'
import { getRequest } from 'mednet-cns/src/api/v1'

import AutoComplete from './headerAutoComplete'

const UserIndex = withPermissions(authItems.editProfile)((props) => (
  <Index indexName={`${props.algoliaNamespace}_user`} />
))

class HeaderSearch extends React.Component {
  render() {
    const { userData, isLoaded, isError } = this.props

    if (!isLoaded || isError) {
      return null
    }

    const { algoliaApp, algoliaKey, algoliaNamespace, userId } = userData

    // Guest user
    if (!userId) {
      return null
    }

    const searchClient = algoliasearch(algoliaApp, algoliaKey)

    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={`${algoliaNamespace}_question`}
      >
        <UserIndex algoliaNamespace={algoliaNamespace} />
        <Index indexName={`${algoliaNamespace}_topic`} />

        <AutoComplete />

        <Configure highlightPreTag="<em>" highlightPostTag="</em>" />
        <Configure hitsPerPage={5} />
      </InstantSearch>
    )
  }
}

const mapStateToProps = (state) => {
  const publicUserRequest = getRequest(
    state,
    FETCH_USER_WITH_PERMISSIONS_PUBLIC
  )
  const userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)

  return {
    userData: state.user.data,
    isLoaded: userRequest.isLoaded || publicUserRequest.isLoaded,
    isError: userRequest.isError || publicUserRequest.isError,
  }
}

export default connect(mapStateToProps)(HeaderSearch)
