import React from 'react'
import includes from 'lodash/includes'
import get from 'lodash/get'
import * as Sentry from '@sentry/browser'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  smartlookClient,
  mixpanel,
  getMixpanelReferrerProperties,
  setMixpanelAgentProperties,
} from 'mednet-util/src/tracking'
import { openModal } from 'mednet-cns/src/reducers/modal'
import { ONBOARDING_MODAL } from 'mednet-util/src/constants/modal'
import { getRequest, mednetAPI } from 'mednet-cns/src/api/v1'
import {
  fetchUserWithPermissions,
  fetchUserWithPermissionsPublic,
  FETCH_USER_WITH_PERMISSIONS,
  FETCH_USER_WITH_PERMISSIONS_PUBLIC,
} from 'mednet-cns/src/reducers/user'

import { OutdatedBrowserPage } from 'pages/outdatedBrowser'
import { ErrorPage } from 'pages/error'

import { publicRoutes } from '../../constants/publicRoutes'

class Manager extends React.Component {
  constructor(props) {
    super(props)

    if (!props.userIsLoaded) {
      props.fetchUserWithPermissions(this.setUser)
    }
  }

  componentDidMount() {
    if (this.props.userIsLoaded) {
      this.setUser()
    }
  }

  componentDidUpdate(prevProp) {
    if (this.props.userIsLoaded) {
      this.setUser()
    } else if (
      prevProp.fetchUserWithPermissions !== this.props.fetchUserWithPermissions
    ) {
      this.props.fetchUserWithPermissions(this.setUser)
    }
  }

  setUser = () => {
    const { openOnboardingModal, userData, userIsLoaded } = this.props

    if (userIsLoaded && userData) {
      const { userId, specialtyId, onboardingQuestion } = userData

      if (!userId) {
        try {
          if (
            mixpanel.get_distinct_id() &&
            // if mixpanel distinct id is a number, this means it is an id from our system and not anonymous
            !isNaN(mixpanel.get_distinct_id())
          ) {
            const referrerProps = getMixpanelReferrerProperties() // get current referrer props before reset
            // Clears super properties and generates a new random distinct_id for this instance.
            mixpanel.reset()
            mixpanel.register(referrerProps)
            setMixpanelAgentProperties()
          }

          // eslint-disable-next-line no-empty
        } catch (e) {}
        return
      }

      try {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: userId,
            specialtyId,
          })
        })
        // eslint-disable-next-line no-empty
      } catch (e) {}

      try {
        smartlookClient.identify(userId)
        // eslint-disable-next-line no-empty
      } catch (e) {}

      try {
        mixpanel.identify(userId)
        mixpanel.register('user_id', userId)

        if (!sessionStorage.getItem('updated_mixpanel_profile')) {
          // update mixpanel profiles from client side asynchronously instead of from server side - which had bad effect on loading time
          mednetAPI(`user/user/updateMixpanelProfile`)
          sessionStorage.setItem('updated_mixpanel_profile', true)
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}

      if (onboardingQuestion) {
        openOnboardingModal()
      }
    }
  }

  render() {
    const { children, userErrors } = this.props

    if (get(window, 'navigator.userAgent', '').match(/MSIE [^10]/g)) {
      return <OutdatedBrowserPage />
    }

    if (userErrors) {
      return <ErrorPage />
    }

    return children
  }
}

const mapStateToProps = (state, ownProps) => {
  let userRequest = undefined

  if (
    !ownProps.location.pathname ||
    ownProps.location.pathname === '/' ||
    publicRoutes.some((publicPath) =>
      includes(ownProps.location.pathname.toLowerCase(), publicPath)
    )
  ) {
    userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS_PUBLIC)
  } else {
    userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)
  }

  return {
    userData: state.user.data,
    userErrors: userRequest.isError === true,
    userIsLoaded: userRequest.isLoaded,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  if (
    !ownProps.location.pathname ||
    ownProps.location.pathname === '/' ||
    publicRoutes.some((publicPath) =>
      includes(ownProps.location.pathname, publicPath)
    )
  ) {
    return {
      fetchUserWithPermissions: (callback) =>
        dispatch(fetchUserWithPermissionsPublic(callback)),
    }
  }

  return {
    fetchUserWithPermissions: (callback) =>
      dispatch(fetchUserWithPermissions(callback)),
    openOnboardingModal: () => dispatch(openModal(ONBOARDING_MODAL.modalId)),
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Manager)
