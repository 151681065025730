import _ from 'lodash'

import { makeURL } from 'mednet-util/src/router'

export const getRefinementTitle = ({
  attribute,
  id,
  title,
  attributeLabel,
}) => {
  if (id === 'boundingBox') {
    return 'Map Area'
  }

  if (title) {
    return title
  }

  if (attributeLabel && attributeLabel[attribute]) {
    return attributeLabel[attribute]
  }

  return _.capitalize(attribute)
}

const getHitID = (hit) => {
  return hit.question_id || hit.user_id || hit.topic_id
}

export const getQueryRefinement = (items) =>
  _.first(items.filter((item) => item.id === 'query'))

export const getHitURL = (hit, params = null) => {
  let object = 'question'
  const objectId = getHitID(hit)

  if (hit.topic_id) {
    object = 'topic'
  } else if (hit.user_id) {
    object = 'user/user/view/id'
  }
  const hitURL = `/${object}/${objectId}`

  const answerMatched =
    hit.answer && hit._highlightResult.answer.paragraph.matchLevel !== 'none'
  const questionMatched =
    hit.question_id && hit._highlightResult.question.matchLevel !== 'none'
  if (questionMatched || !answerMatched) {
    return makeURL(hitURL, params)
  }

  return makeURL(hitURL, params, false, `#ans_${hit.answer.answer_id}`)
}

export const transformSearchUserObject = (hit) => {
  const {
    firstname,
    middlename,
    lastname,
    institution_id,
    picture_version,
    user_id,
    _highlightResult,
    ...rest
  } = hit
  const {
    firstname: highLightFirstname,
    middlename: highLightMiddlename,
    lastname: highLightLastname,
    ...restHighlight
  } = _highlightResult || {}

  return {
    firstName: firstname,
    middleName: middlename,
    lastName: lastname,
    institutionId: institution_id,
    imageVersion: picture_version,
    userId: user_id,
    _highlightResult: {
      firstName: highLightFirstname,
      middleName: highLightMiddlename,
      lastName: highLightLastname,
      ...restHighlight,
    },
    ...rest,
  }
}

export const transformSearchGeoObject = (hit) => {
  const { geo_name, geo_id, geo_type_id } = hit

  return {
    geoName: geo_name,
    geoId: geo_id,
    geoTypeId: geo_type_id,
  }
}

export const transformSearchInstitutionObject = (hit) => {
  const { institution_id, institution_types, hiring_contacts, ...rest } = hit

  return {
    institutionId: institution_id,
    institutionTypes: institution_types,
    hiringContacts: hiring_contacts,
    ...rest,
  }
}

export const transformTopicObject = (hit) => {
  const { topic_id, ...rest } = hit

  return {
    topicId: topic_id,
    ...rest,
  }
}

export const getGeoGroupID = (hit) => {
  if (!hit._geoloc) {
    return ''
  }

  return `${hit._geoloc.lat}_${hit._geoloc.lng}`
}
