import React from 'react'
import _ from 'lodash'
import { Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { GlobalEventListener } from 'react-global-event-listener'

import { BaseQuestionLink } from 'pharmacy/src/navigation/questionLink'
import { BaseUserNameLink } from 'pharmacy/src/navigation/userNameLink'
import { BaseUserLink } from 'pharmacy/src/navigation/userLink'
import { NavigationContext } from 'pharmacy/src/contexts/navigation'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'
import { Tracking } from 'pharmacy/src/misc/tracking'

import { Manager } from 'components/manager'
import { AnteriorHeader } from 'components/anteriorHeader'
import { Mention } from 'components/mention'
import { OnboardingModal } from 'components/onboardingModal'
import { ROUTES } from 'constants/routes'

import useNotificationIdScrubbing from 'anterior-util/useNotificationIdScrubbing'

import 'sanitize.css'
import 'pharmacy/src/style/app.scss'

const App = ({ history }) => {
  useNotificationIdScrubbing(history)

  return (
    <ErrorBoundary>
      <GlobalEventListener>
        <NavigationContext.Provider
          value={{
            UserNameLinkComponent: BaseUserNameLink,
            QuestionLinkComponent: BaseQuestionLink,
            UserLinkComponent: BaseUserLink,
          }}
        >
          <Helmet titleTemplate="theMednet - %s" defaultTitle="theMednet">
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />

            {/* <!-- Meta Pixel Code --> */}
            {!process.env.DEBUG && (
              <script>
                {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '1679094909058076');
                    fbq('track', 'PageView');
                    `}
              </script>
            )}

            {!process.env.DEBUG && (
              <noscript>
                {`<img
                    height="1"
                    width="1"
                    style="display:none"
                    src="https://www.facebook.com/tr?id=1679094909058076&ev=PageView&noscript=1"
                  />`}
              </noscript>
            )}
            {/* <!-- End Meta Pixel Code --> */}

            {/* <!-- Twitter conversion tracking base code --> */}
            {!process.env.DEBUG && (
              <script>
                {`!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
                  },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
                  a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
                  twq('config','ocduq');
                  window.twq('track', 'PageView');
                  `}
              </script>
            )}
            {/* <!-- End Twitter conversion tracking base code --> */}
          </Helmet>
          <Manager>
            <ErrorBoundary>
              <Tracking history={history} />
            </ErrorBoundary>
            <ErrorBoundary>
              <AnteriorHeader history={history} />
            </ErrorBoundary>
            <ErrorBoundary>
              <OnboardingModal />
            </ErrorBoundary>
            <ErrorBoundary>
              <Mention />
            </ErrorBoundary>
            <Switch>
              {_.map(ROUTES, (routeObj) => {
                const { label, ...routeProps } = routeObj
                return <Route key={routeObj.path} {...routeProps} />
              })}
            </Switch>
          </Manager>
        </NavigationContext.Provider>
      </GlobalEventListener>
    </ErrorBoundary>
  )
}

export default App
